<template>
    <form @submit.prevent="onSubmit(true)" class="quiz__set">
        <div class="md-layout mb-10">
            <div class="md-layout-item">
                <h2 class="text-uppercase text-2xl">Create new quiz</h2>
            </div>
            <div class="md-layout-item flex justify-end">
                <md-button @click="$router.go(-1)" class="rounded">
                <md-icon>list</md-icon>  Back Quiz List
                </md-button>
            </div>
        </div>
        <div class="md-layout md-gutter flex align-center mt-6">
          <div class="md-layout-item md-size-17">
              <p class="text-uppercase text-base font-semibold">Quiz Question Title</p>
          </div>
          <div class="md-layout-item md-size-50">
            <InputFieldComponent
               v-model.trim="form.index"
               :message="$v.form.index.$dirty && !$v.form.index.required ? 'Field is required' : null"
                :class="{'vuelidate-error': $v.form.index.$error}"
            />
          </div>
        </div>
        <div class="md-layout md-gutter flex align-center mt-6">
            <div class="md-layout-item md-size-17">
                <p class="text-uppercase text-base font-semibold">Question Type</p>
            </div>
            <div class="md-layout-item md-size-25">
                <SelectComponent
                    :items="questionTypes"
                    class="w-full"
                    :placeholder="false"
                    return-type="index"
                    @getIndex="getQuestionIndex"
                    v-model.trim="form.questionType"
                />
            </div>
            <div class="md-layout-item md-size-15">
                <p class="text-uppercase text-base font-semibold">Answer Type</p>
            </div>
            <div class="md-layout-item md-size-25">
                <SelectComponent
                    :items="answerTypes"
                    class="w-full"
                    :placeholder="false"
                    return-type="index"
                    @getIndex="getAnswerIndex"
                    v-model.trim="form.answer_type"
                />
            </div>
        </div>
        <div class="md-layout md-gutter flex align-center mt-6">
          <div class="md-layout-item md-size-17">
              <p class="text-uppercase text-base font-semibold">TAGS</p>
          </div>
          <div class="md-layout-item md-size-65">
            <TagInput v-model.trim="form.tags"/>
          </div>
        </div>
        <div class="md-layout md-gutter flex mt-6" v-if="answerComponent === answerTypes[0].component">
            <div class="md-layout-item md-size-17">
              <p class="text-uppercase text-base font-semibold">Negative Marking</p>
            </div>
            <div class="md-layout-item md-size-65">  
                <md-radio v-model="negative_marking" :value="0">No</md-radio>
                <md-radio v-model="negative_marking" :value="1">Yes</md-radio>
            </div>
        </div>
        <div class="md-layout md-gutter flex align-center mt-6">
          <div class="md-layout-item md-size-100">
            <TextEditor 
              label="Quiz Prompt"
              v-model.trim="form.transcript"
            />
          </div>
        </div>
      <div class="my-6" style="border-bottom:1px solid #cbd5e0">
          <h2 class="text-uppercase text-victoria pb-3">Question</h2>
      </div>

      <components ref="parent" :is="component" :value="question_title" :videoUrl="videoUrl" :audioUrl="audioUrl" :imageUrl="imageUrl"></components>
      <div class="my-6 flex justify-between" style="border-bottom:1px solid #cbd5e0">
          <h2 class="text-uppercase text-victoria pb-3">Answer</h2>
          <div v-if="answerComponent === 'TextAnswer'">
            <h4 class="text-uppercase text-victoria pb-3">Mark: {{ this.markCount }}</h4>
          </div>
      </div>
      <components ref="child" :is="answerComponent" @input="WordCounter" :isCreateQuiz="true" :answerOption="answer_option" :answer_title="answer"></components>
      <b class="text-danger text-sm" v-if="this.formErrors">{{ this.formErrors}}</b>
      <div class="flex justify-end mt-6">
          <md-button @click="onSubmit(false)" :disabled="loading" :loading="loading" class="bg-default rounded">Save Draft </md-button>
          <Button  :disabled="loading" :loading="loading" label="Publish" type="submit" class="mr-0 bg-victoria text-white rounded">Publish</Button>
      </div>
    </form>
</template>

<script>
import {
    Button,
    InputFieldComponent,
    TextAreaComponent,
    SelectComponent
} from "@/components";
import Questions from "@/components/molecule/pte-quiz";
import TextEditor from "@/components/atom/form/TextEditor";
import TagInput from "@/components/atom/form/TagInput";
import data from "@/data/pte-quiz/data";
import { required } from "vuelidate/lib/validators";
import { scrollToFirstError } from "@/utils/scrollToFirstError";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
    components: {
        Button,
        InputFieldComponent,
        SelectComponent,
        TextAreaComponent,
        TextEditor,
        TagInput,
        ...Questions
    },
    data() {
        return {
            loading: false,
            questionTypes: data.questionTypes,
            answerTypes: data.answerTypes,
            component: 'AudioAndText',
            quiz_id: '',
            questionType: 'VideoAndText',
            answerComponent: 'MCQChooseMultipleAnswer',
            answerType: "MCQ-CHOOSE-MULTIPLE-ANSWER",
            form: {
                active: false,
                quiz_type_id: "",
                index: "",
                transcript: "",
                tags: [],
            },
            negative_marking: 0,
            markCount: 0,
            question_title: '',
            videoUrl: '',
            audioUrl: '',
            imageUrl: '',
            answer_option: [],
            answer: '',
            formErrors: '',
        }
    },
    computed: {
        ...mapGetters({
            getQuiz: "question/getQuiz",
            getSelectedQuiz: "question/getSelectedQuiz",
        }),
        quizId() {
            return this.$route.params && this.$route.params.id
        },
        validationRules() {
            return {
                index: { required },
            };
        }
    },
    methods: {
        ...mapMutations({
            dialog: "setShowDialog",
            setShowSnackbar: "setShowSnackbar",
            setSelectedQuiz: "question/setSelectedQuiz",
            setQuiz: "question/setQuiz",
        }),
        ...mapActions({
           submitQuiz: 'question/actSubmitQuiz',
           actGetQuiz: "question/actGetQuiz",
           actQuizDetails: "question/actQuizDetails",
           actQuizUpdate: "question/actQuizUpdate"
        }),
        getQuestionIndex(index) {
           this.component = this.questionTypes[index].component;
           this.quiz_id = this.getQuiz[index].id
        },
        getAnswerIndex(index) {
           this.answerComponent = this.answerTypes[index].component;
           this.answerType = this.answerTypes[index].type;
        },
        validateQuizOptions() {
            const answerForm = this.$refs.child.form
            if(answerForm.quiz_options){
                const quiz_options = Array.isArray(answerForm.quiz_options) ? answerForm.quiz_options : [];
                const hasMinLength = quiz_options.length >= 3;
                const allHaveValidTitle = quiz_options.every(option =>
                    option && typeof option === 'object' && 'title' in option && typeof option.title === 'string' && option.title.length >= 2
                );
                return !hasMinLength || !allHaveValidTitle;
            } else {
                const hasMinLength = answerForm.answer.length  !== 0
                return !hasMinLength
            }
        },

        onSubmit(active) {
            this.loading = true;
            this.$v.$touch();
            this.formErrors = '';
            if(this.$v.$invalid || this.validateQuizOptions()) {
                if(this.validateQuizOptions()){
                    const answerForm = this.$refs.child.form
                    this.formErrors = answerForm.quiz_options ? 'Answer must have at least 4 items and At least one field check' : 'Field is required';
                }
                setTimeout(()=> {
                    scrollToFirstError()
                },10)
                this.loading = false;
                return;
            }
            this.form.quiz_type_id = this.quiz_id;
            this.form.active = active;
            this.form.answer_type = this.answerType;
            if(this.answerComponent === 'MCQChooseMultipleAnswer'){
                this.form.negative_marking = this.negative_marking;
            }
            if(typeof this.form.title != 'string') {
                this.form.title = '';
            }
            if(this.form.answer_type === 'TEXT'){
                this.form.quiz_options = undefined
            } else {
                this.form.answer = undefined
            }
            this.form = {...this.form, ...this.$refs.child.form,...this.$refs.parent.form}
            this[(active == false) ? 'draftLoading' : 'loading'] = true;
            if(this.quizId){
               this.actQuizUpdate({
                    quizId: this.quizId,
                    payload: this.form
                }).then(response => {
                    this.loading = false;
                    this.setShowSnackbar(response.message);
                    if(!this.createAnother) {
                    this.$router.go(-1)
                    }
                }).catch(error => {
                    this.loading = false;
                    this.setShowSnackbar(error.response.data.message);
                })
                .finally(() => this[(active == false) ? 'draftLoading' : 'loading'] = false);
            } else {
                this.submitQuiz(this.form)
                .then(response => {
                    this.loading = false;
                    this.setShowSnackbar(response.message);
                    if(!this.createAnother) {
                    this.$router.go(-1)
                    }
                }).catch(error => {
                    this.loading = false;
                    this.setShowSnackbar(error.response.data.message);
                })
                .finally(() => this[(active == false) ? 'draftLoading' : 'loading'] = false);
            }        
        },
        WordCounter(value){
          const search_contents = value.replace(/(<p[^>]+?>|<p>|<\/p>)/g, "");
          let wordsList = search_contents.match(/(\w+)/g);
          if(wordsList.length <= 9){
            this.markCount = '0'+wordsList.length;
          } else{
            this.markCount = wordsList.length;
          }
        },
        fetchQuiz() {
            this.actQuizDetails(this.quizId).then(quiz => {
                this.form.quiz_type_id = quiz.quiz_type_id;
                this.form.index = quiz.index
                this.form.questionType = quiz.quiz_type_slug
                this.form.answer_type = quiz.answer_type
                this.form.tags = quiz.tags
                this.negative_marking = quiz.negative_marking
                this.form.transcript = quiz.transcript
                this.question_title = quiz.title
                this.audioUrl = quiz.audio_resource_path || '';
                this.imageUrl = quiz.image_resource_path || '';
                this.videoUrl = quiz.video_resource_path || '';
                this.answer_option = quiz.quiz_options.map((option, index) => {
                    return {
                        id: index+1,     
                        title: option.key ,
                        value: option.key ,
                        is_correct_answer: option.is_correct_answer === 1  
                    };
                })
                this.answer = quiz.answer
                const foundQuestionType = this.questionTypes.find(type => type.id === quiz.quiz_type_slug);
                if (foundQuestionType) {
                    this.component = foundQuestionType.component;
                }
                const foundAnswerType = this.answerTypes.find(type => type.type === quiz.answer_type);
                if (foundAnswerType) {
                    this.answerComponent = foundAnswerType.component;
                    this.answerType = foundAnswerType.type;
                }
                quiz.answer && this.WordCounter(quiz.answer)
            })
        }
    },
    watch: {
        'form.index'() {
          this.$v.form.index.$touch();
        },
    }, 
    validations() {
        return {
            form: this.validationRules
        };
    },
    mounted() {
        this.actGetQuiz(`?section=${this.component}`)
        .then(() => {
            if (this.getQuiz && this.getQuiz.length > 0) {
                this.quiz_id = this.getQuiz[0].id;
            } else {
                console.error('Quiz data is not available');
            }
        });
        this.component = this.questionTypes[0].component;
        this.answerComponent = this.answerTypes[0].component;
        this.answerType = this.answerTypes[0].type; 
    },
    created() {
        this.quizId && this.fetchQuiz();
    }
}
</script>