<template>
  <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-45">
          <VideoRecord v-model="form.video_resource_path" @input="input" />
      </div>
      <div class="md-layout-item md-size-55 video_quiz">
          <TextEditor
                :label="false"
                v-model.trim="form.title"
            />
      </div>

  </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import VideoRecord from "@/components/atom/media/video/video";
export default {
    components: {
      TextEditor,
      VideoRecord,
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      videoUrl: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        form: {
          title: this.value,
          video_resource_path: this.videoUrl
        },
      }
    },
    watch: {
      value(newValue) {
        this.form.title = newValue;
      },
      videoUrl(newValue) {
        this.form.video_resource_path = newValue;
      }
    },
    methods:{
      input(url){
        this.form.video_resource_path = url;
      }
    }
}
</script>
