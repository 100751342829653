<template>
  <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-100">
          <TextEditor
              class="h-64"
              :label="false"
              v-model.trim="form.title"
            />
      </div>
  </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
export default {
    components: {
        TextEditor
    },
    props: {
      value: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        form: {
          title: this.value
        },
      }
    },
}
</script>
