<template>
  <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-45">
           <AudioUploader
              style="margin: 0 auto"
              v-model="form.audio_resource_path"
              :fileUrl="this.audioUrl"
          />
        </div>
        <div class="md-layout-item md-size-55 audio_quiz">
            <TextEditor
                :label="false"
                v-model.trim="form.title"
            />
        </div>
  </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import AudioUploader from "@/components/atom/media/AudioUploader";
export default {
    components: {
        TextEditor,
        AudioUploader,
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      audioUrl: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        form: {
          title: this.value,
          audio_resource_path: this.audioUrl
        },
      }
    },
    watch: {
      value(newValue) {
        this.form.title = newValue;
      },
      audioUrl(newValue) {
        this.form.video_resource_path = newValue;
      }
    }
}
</script>