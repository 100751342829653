<template>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-100">
        <TextEditor
          class="h-64"
          :label="false"
          v-model.trim="form.answer"
          @input="input"
        />
      </div>
    </div>
  </template>
  
  <script>
  import TextEditor from "@/components/atom/form/TextEditor";
  
  export default {
    components: {
      TextEditor
    },
    props: {
      answer_title: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        form: {
          answer: this.answer_title || ''
        }
      };
    },
    methods: {
      input(val) {
        this.$emit('input', val);
      }
    },
    watch: {
      answer_title(newValue) {
        if (newValue !== this.form.answer) {
          this.form.answer = newValue;
        }
      }
    }
  };
  </script>

  