<template>
  <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-40 img_quiz">
          <ImageUploader v-model="form.image_resource_path" :url="this.imageUrl"/>
      </div>
      
      <div class="md-layout-item md-size-60 audio_quiz">
            <TextEditor
                :label="false"
                v-model.trim="form.title"
            />
      </div>

  </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import ImageUploader from "@/components/atom/media/ImageUploader";
export default {
    components: {
        TextEditor,
        ImageUploader
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      imageUrl: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        form: {
          title: this.value,
          image_resource_path: this.imageUrl
        },
      }
    },
    watch: {
      value(newValue) {
        this.form.title = newValue;
      },
      imageUrl(newValue) {
        this.form.video_resource_path = newValue;
      }
    }
}
</script>
